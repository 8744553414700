<template>
  <v-card
    :class="{ 'user-chart': !isDefault }"
    class="ma-0 px-5 d-flex align-center"
    style="height: 60px;"
    elevation="1"
  >
    <template v-if="id">
      <!-- <v-icon
        v-if="isPro"
        class="handle mr-2"
        style="cursor: move;"
      >
        mdi-drag
      </v-icon> -->
      <span
        v-if="!isDefault"
        style="cursor: pointer; width: 100%;"
        @click="$emit('click')"
      >
        {{ title }}
      </span>
      <span
        v-else
        :class="{ 'user-chart': !isDefault }"
      >
        {{ title }}
      </span>
      <div class="ml-auto">
        <v-switch
          v-model="value"
          :loading="loading"
          class="ma-0 pa-0"
          hide-details
          @change="change"
        />
      </div>
    </template>

    <template v-else>
      <div
        class="d-flex justify-center"
        style="cursor:pointer; width: 100%; height: 100%"
        @click="$emit('click')"
      >
        <v-icon size="24">
          mdi-plus
        </v-icon>
      </div>
    </template>
  </v-card>
</template>
<script>
import alert from '@/common/utils/alert'

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      value: this.isActive,
    }
  },

  computed: {
    isPro() {
      return this.$store.getters['user/isPro']
    },
  },

  watch: {
    isActive(value) {
      this.value = value
    },
  },

  methods: {
    change(value) {
      this.loading = true
      const payload = {
        is_active: value,
      }
      this.$httpUser.put(`api/charts/${this.id}`, payload)
        .then(() => {
          this.$store.commit('chart/SET_CHART_STATUS', { id: this.id, isActive: value })
        })
        .catch(() => {
          alert.fail('Cập nhật thất bại.')
          this.value = !value
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.user-chart {
  border: 1px solid rgb(145, 85, 253, .6);
  color: rgb(145, 85, 253);
}
.user-chart span {
  color: rgb(145, 85, 253);
}
</style>
