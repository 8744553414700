<template>
  <v-card
    flat
    class="mt-2"
  >
    <v-tabs-items
      v-model="tab"
      touchless
    >
      <v-tab-item>
        <template>
          <div class="pa-3">
            <v-card-title>
              Thành phần
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-switch
                    v-model="chartOptions.dataLabels"
                    label="Số liệu"
                    hide-details
                    @click="updateChartOptions"
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-switch
                    v-model="chartOptions.legend"
                    label="Chú thích"
                    hide-details
                    @click="updateChartOptions"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </div>

          <v-divider />

          <div class="pa-3">
            <v-card-title>
              <div style="width: 250px">
                <v-select
                  v-model="businessType"
                  :items="businessTypes"
                  dense
                  outlined
                  hide-details
                />
              </div>
            </v-card-title>
            <v-card-text>
              <draggable
                v-if="chartList"
                v-model="chartList"
                class="row"
                ghost-class="sortable-ghost"
                drag-class="sortable-drag"
                handle=".handle"
                animation="200"
                @start="drag=true"
                @end="drag=false"
              >
                <v-col
                  v-for="(chart, index) in chartList"
                  :key="chart.id"
                  cols="12"
                  md="6"
                >
                  <ChartItem
                    :id="chart.id"
                    :index="index"
                    :title="chart.title"
                    :is-active="chart.is_active"
                    :is-default="chart.is_default"
                    @click="showBuilder(index, chart)"
                  />
                </v-col>
                <!-- <v-col
                  v-if="isPro"
                  cols="12"
                  md="6"
                >
                  <ChartItem
                    :is-default="true"
                    @click="showBuilder(null, null)"
                  />
                </v-col> -->
              </draggable>
            </v-card-text>
          </div>
        </template>
      </v-tab-item>
      <v-tab-item>
        <ChartBuilder
          v-if="tab===1"
          :index="chartIndex"
          :chart-id="chartData.id"
          :initial-title="chartData.title"
          :initial-indicators="chartData.indicators"
          :is-active="chartData.is_active"
          @doneBuilding="showList"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import alert from '@/common/utils/alert'
import ChartItem from './ChartItem.vue'
import ChartBuilder from './ChartBuilder.vue'

export default {
  components: {
    draggable,
    ChartItem,
    ChartBuilder,
  },

  data() {
    return {
      chartIndex: -1,
      chartData: { id: null, title: '', indicators: [] },
      tab: 0,
      businessType: 1,
      businessTypes: [
        {
          text: 'Công ty cổ phần',
          value: 1,
        },
        {
          text: 'Công ty chứng khoán',
          value: 2,
        },
        {
          text: 'Ngân hàng',
          value: 3,
        },
      ],
    }
  },

  computed: {
    ...mapGetters('chart', ['charts']),
    ...mapGetters('user', ['chartOptions', 'isPro']),
    chartList: {
      get() {
        return this.charts.filter(chart => chart.business_type === this.businessType)
      },
      set(value) {
        this.$store.commit('chart/SET_CHARTS', value)
        localStorage.setItem('charts', JSON.stringify(value))
      },
    },
  },

  created() {
    if (!this.charts.length) {
      this.FETCH_CHARTS()
    }
  },

  methods: {
    ...mapActions('chart', ['FETCH_CHARTS']),
    updateChartOptions() {
      this.$nextTick(() => {
        this.$httpUser.put('/api/user/settings/chart-options', {
          data_labels: this.chartOptions.dataLabels,
          legend: this.chartOptions.legend,
        })
          .catch(() => {
            alert.fail('Cập nhật thất bại.')
          })
      })
    },
    showBuilder(index, data) {
      this.chartIndex = index
      this.chartData = data || { id: null, title: '', indicators: [] }
      this.tab = 1
    },
    showList() {
      this.chartData = null
      this.tab = 0
    },
  },
}
</script>

<style scoped>
  .sortable-ghost .v-card {
    border: 2px solid #9155FD !important;
  }
  .sortable-drag .v-card {
    opacity: 0.1;
  }
</style>
