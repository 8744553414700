<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :error-messages="passwordError"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                label="Mật khẩu hiện tại"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :error-messages="newPasswordError"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                label="Mật khẩu mới"
                outlined
                dense
                class="mt-3"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :error-messages="cPasswordError"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                label="Xác nhận mật khẩu mới"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>

              <v-btn
                color="primary"
                class="me-3 mt-3"
                @click="save"
              >
                Lưu thay đổi
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <!-- <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img> -->
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import alert from '@/common/utils/alert'

export default {
  data() {
    return {
      errors: {},
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      currentPassword: '',
      newPassword: '',
      cPassword: '',
    }
  },

  computed: {
    passwordError() {
      return this.errors.password || this.errors.auth || ''
    },
    newPasswordError() {
      return this.errors.new_password || ''
    },
    cPasswordError() {
      return this.errors.confirm_password || ''
    },
  },

  methods: {
    save() {
      this.errors = {}

      if (this.newPassword !== this.cPassword) {
        this.errors = {
          new_password: 'Mật khẩu không trùng khớp',
          confirm_password: 'Mật khẩu không trùng khớp',
        }

        return
      }

      const payload = {
        password: this.currentPassword,
        new_password: this.newPassword,
      }
      this.$httpUser.put('/api/auth/user/password', payload)
        .then(() => {
          this.currentPassword = ''
          this.newPassword = ''
          this.cPassword = ''
          alert.success('Cập nhật thành công')
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.errors = err.response.data.errors
          } else if (err.response.data.message) {
            alert.fail(err.response.data.message)
          } else {
            alert.fail('Cập nhật thành công')
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
// .security-character {
//   position: absolute;
//   bottom: -0.5rem;
// }
</style>
