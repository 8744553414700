<template>
  <v-card
    class="my-5 px-2 d-flex align-center"
    style="height: 45px"
    elevation="1"
  >
    <template v-if="indicatorId">
      <v-icon
        left
        style="cursor: move"
      >
        mdi-drag
      </v-icon>

      <div
        class="mr-4 d-flex align-center"
        style="cursor:pointer; width:100%;"
        @click="$emit('click')"
      >
        <div>
          {{ label }}
        </div>

        <v-icon
          class="ml-auto mr-0 pr-2"
          :color="color"
          left
        >
          {{ types[type].icon }}
        </v-icon>
      </div>

      <v-icon
        class="pa-1 ma-0 ml-auto"
        @click="$emit('remove', indicatorId)"
      >
        mdi-close
      </v-icon>
    </template>

    <template v-else>
      <div
        class="d-flex justify-center py-1"
        style="cursor:pointer; width: 100%;"
        @click="$emit('click')"
      >
        <v-icon>mdi-plus</v-icon>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    indicatorId: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'bar',
    },
    color: {
      type: String,
      default: '#16B1FF',
    },
  },

  data() {
    return {
      types: {
        line: {
          label: 'Đường',
          icon: 'mdi-chart-line',
        },
        bar: {
          label: 'Cột',
          icon: 'mdi-chart-bar',
        },
        'stacked-bar': {
          label: 'Cột chồng',
          icon: 'mdi-chart-bar-stacked',
        },
      },
    }
  },
}
</script>
