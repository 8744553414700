<template>
  <v-dialog
    v-model="dialog"
    max-width="695px"
  >
    <v-card class="pa-5">
      <v-row>
        <v-col cols="5">
          <v-card outlined>
            <v-virtual-scroll
              bench="50"
              :items="Object.values(indicators)"
              height="475"
              item-height="48"
            >
              <template v-slot:default="{ item }">
                <v-list-item
                  :key="item.id"
                  @click="id=item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.label" />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>

                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
        <v-col cols="7">
          <div class="text-center">
            <span>
              {{ id ? indicators[id].label : '--- Chưa chọn chỉ số ---' }}
            </span>
          </div>
          <v-radio-group
            v-model="type"
            row
          >
            <v-radio
              v-for="t in types"
              :key="t.value"
              :value="t.value"
            >
              <template v-slot:label>
                <v-icon
                  small
                  left
                >
                  {{ t.icon }}
                </v-icon>
                <div>
                  {{ t.name }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-color-picker
            v-model="color"
            canvas-height="120"
            width="600"
            mode="hexa"
            flat
            show-swatches
            :swatches="swatches"
          />
          <div class="mt-5 pa-0 d-flex justify-center">
            <v-btn
              class="mx-2"
              color="primary"
              outlined
              @click="close"
            >
              Hủy
            </v-btn>
            <v-btn
              class="mx-2"
              :disabled="id===null"
              color="primary"
              @click="save"
            >
              Lưu
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      index: null,
      id: null,
      type: 'bar',
      color: '#16B1FF',

      types: [
        {
          name: 'Đường',
          value: 'line',
          icon: 'mdi-chart-line',
        },
        {
          name: 'Cột',
          value: 'bar',
          icon: 'mdi-chart-bar',
        },
        {
          name: 'Cột chồng',
          value: 'stacked-bar',
          icon: 'mdi-chart-bar-stacked',
        },
      ],

      swatches: [
        ['#16B1FF', '#FFB400'],
        ['#FF4C51', '#9155FD'],
        ['#8A8D93', '#56CA00'],
        ['#0D6EFD', '#C68500'],
        ['#C50028', '#5A25C9'],
      ],
    }
  },

  computed: {
    indicators() {
      const inds = this.$store.getters['setting/indicators']
      if (inds === null) {
        return []
      }

      const result = {}
      inds.forEach(item => {
        result[item.id] = item
      })

      return result
    },

    indicatorLabels() {
      const labels = {}
      this.indicators.forEach(item => {
        labels[item.id] = item.label
      })

      return labels
    },
  },

  methods: {
    open(index, data) {
      this.dialog = true

      this.index = index
      this.id = data ? data.id : null
      this.type = data ? data.options.type : 'line'
      this.color = data ? data.options.color : '#16B1FF'
    },

    close() {
      this.index = null
      this.id = null
      this.type = 'bar'
      this.color = '#16B1FF'
      this.dialog = false
    },

    save() {
      const { unit } = this.indicators[this.id]
      const data = {
        id: this.id,
        unit,
        options: {
          type: this.type,
          color: this.color,
        },
      }
      this.$emit('save', this.index, data)
      this.close()
    },
  },
}

</script>
