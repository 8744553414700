import store from '@/store'

export default {
  success(msg) {
    store.commit('SHOW_MESSAGE', {
      text: msg,
      color: 'success',
    })
  },

  fail(msg) {
    store.commit('SHOW_MESSAGE', {
      text: msg,
      color: 'error',
    })
  },
}
