<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="320"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-auto py-5"
        color="error"
        outlined
        v-bind="attrs"
        :disabled="!chartId || deleting"
        v-on="on"
      >
        <v-progress-circular
          v-if="deleting"
          :size="14"
          :width="2"
          indeterminate
        />
        <span v-else>
          <v-icon left>
            mdi-delete
          </v-icon>
          Xóa
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 justify-center">
        Xác nhận
      </v-card-title>
      <v-card-text class="text-center">
        Bạn có chắc chắn muốn xoá biểu đồ này?
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          text
          @click="dialog=false"
        >
          Hủy
        </v-btn>
        <v-btn
          color="error"
          text
          @click="dialog=false; deleting=true; $emit('confirmDelete')"
        >
          Xóa
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: null,
    },
    chartId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    }
  },
}
</script>
