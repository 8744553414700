<template>
  <v-card>
    <v-tabs
      v-model="tabId"
      show-arrows
    >
      <v-tab
        v-for="(t, key) in tabs"
        :key="key"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ t.icon }}
        </v-icon>
        <span>{{ t.title }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabId">
      <v-tab-item>
        <Chart />
      </v-tab-item>
      <v-tab-item>
        <Security />
      </v-tab-item>
      <v-tab-item>
        <Notification />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Chart from '../components/Chart.vue'
import Security from '../components/Security.vue'
import Notification from '../components/Notification.vue'

export default {
  components: {
    Chart,
    Security,
    Notification,
  },

  data() {
    const tabs = [
      {
        title: this.$t('chart-options'),
        icon: 'mdi-chart-box-outline',
        tab: 'chart',
      },
      {
        title: 'Bảo mật',
        icon: 'mdi-lock-open-outline',
        tab: 'security',
      },
      {
        title: 'Thông báo',
        icon: 'mdi-bell-outline',
        tab: 'notification',
      },
    ]
    const { tab } = this.$route.params

    let tabId = 0
    for (let i = 0; i < tabs.length; i += 1) {
      if (tab === tabs[i].route) {
        tabId = i
        break
      }
    }

    return {
      tabId,
      tabs,
    }
  },

  watch: {
    tabId(value) {
      const { tab } = this.tabs[value]
      this.$router.replace({ name: 'setting-tab', params: { tab } })
    },
  },

  created() {
    this.$store.dispatch('setting/FETCH_INDICATORS')
  },
}
</script>
