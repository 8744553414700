import { INTERVAL } from '@/common/constants'

export default {
  props: {
    symbol: {
      type: String,
      default: '',
    },
    interval: {
      type: Number,
      default: INTERVAL.QUARTERLY,
    },
    end: {
      required: true,
      type: String,
    },
    range: {
      required: true,
      type: Number,
    },
    indicators: {
      required: true,
      type: Array,
    },
    unit: {
      type: String,
      default: 'thousand',
      validator: val => ['percentage', 'one', 'thousand', 'million', 'billion'].includes(val),
    },
  },

  data() {
    return {
      data: [],
      loading: false,
      conversionTable: {
        billion: 1000000,
        million: 1000,
        thousand: 1,
        one: 1,
        none: 1,
      },
    }
  },

  watch: {
    symbol() {
      this.fetchData()
    },
    range() {
      this.fetchData()
    },
    end() {
      this.fetchData()
    },
  },

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      if (this.indicators.length === 0 || this.symbol === '' || this.end === '') {
        return
      }
      const { symbol, end, range } = this

      let units = {}
      let indicatorIds = []
      this.indicators.forEach(({ id, unit }) => {
        units = { ...units, [id]: unit }
        indicatorIds = [...indicatorIds, id]
      })

      const url = `/api/companies/${symbol}/indicators`
      const params = {
        ids: indicatorIds.join(','),
        end,
        range,
      }

      this.loading = true
      this.$httpUser.get(url, { params })
        .then(response => {
          const { data } = response.data
          // Rounding values
          data.forEach(indicator => {
            const unitName = units[indicator.id]
            if (unitName === 'percentage') {
              indicator.values = indicator.values.map(v => (v * 100).toFixed(2))
            } else if (unitName !== 'none') {
              const conversionValue = this.conversionTable[unitName]
              indicator.values = indicator.values.map(v => Math.round(v / conversionValue))
            }
          })
          this.data = data
        })
        .catch(() => {
          this.data = []
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  render() {
    return this.$scopedSlots.default({
      data: this.data,
      loading: this.loading,
    })
  },
}
