<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <div style="height: 272px">
                <p>
                  Nhận thông báo bài viết mới qua Email
                </p>
                <v-btn
                  :color="subscribed ? 'error' : 'primary'"
                  class="me-3 mt-3"
                  :outlined="subscribed"
                  @click="save()"
                >
                  {{ subscribed ? 'Hủy đăng ký' : 'Đăng ký' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import alert from '@/common/utils/alert'

export default {
  computed: {
    subscribed() {
      return this.$store.getters['user/subscribed']
    },
  },
  methods: {
    save() {
      const method = this.subscribed ? 'delete' : 'post'
      this.$httpUser.request({ method, url: '/api/articles/mailing' })
        .then(() => {
          this.$store.commit('user/SET_SUBSCRIPTION', !this.subscribed)
          alert.success('Cập nhật thành công')
        })
        .catch(() => {
          alert.fail('Cập nhật thất bại')
        })
    },
  },
}
</script>
