<template>
  <v-card class="pa-3">
    <v-card-title class="mb-5">
      Dựng biểu độ
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="title"
            label="Tên biểu đồ"
            outlined
            dense
            hide-details
          />

          <IndicatorItem
            v-for="(item, i) in indicators"
            :key="i"
            :indicator-id="item.id"
            :label="labels[item.id]"
            :type="item.options.type"
            :color="item.options.color"
            @remove="removeItem"
            @click="$refs.indicatorModal.open(i, item)"
          />
          <IndicatorItem
            :indicator-id="null"
            @click="$refs.indicatorModal.open(null, null)"
          />

          <v-btn
            class="mr-3"
            color="primary"
            outlined
            @click="$emit('doneBuilding')"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Hủy
          </v-btn>

          <v-btn
            color="primary"
            :disabled="indicators.length === 0 || saving"
            @click="saveChart"
          >
            <v-progress-circular
              v-if="saving"
              :size="14"
              :width="2"
              indeterminate
            />
            <span v-else>
              <v-icon left>
                mdi-content-save
              </v-icon>
              Lưu
            </span>
          </v-btn>
        </v-col>

        <v-col cols="7">
          <DataProvider
            ref="dataProvider"
            symbol="vnm"
            :interval="interval"
            :times="times"
            :end="times ? times[times.length - 1] : ''"
            :range="8"
            :indicators="indicators"
          >
            <template v-slot="{ data }">
              <Chart
                v-if="showChartItems"
                :title="title"
                :data="data"
                :indicators="indicators"
                :times="times"
                :show-data-label="showDataLabel"
                :show-legend="showLegend"
                :elevation="1"
              />
            </template>
          </DataProvider>

          <div class="d-flex align-center pt-3">
            <v-card
              elevation="1"
              width="calc(100% - 130px)"
            >
              <div class="ma-0 pa-3 d-flex justify-space-around">
                <v-switch
                  v-model="showDataLabel"
                  class="ma-0 pa-0"
                  label="Chỉ số"
                  hide-details
                  @change="reloadChart"
                />
                <v-switch
                  v-model="showLegend"
                  class="ma-0 pa-0"
                  label="Chú thích"
                  hide-details
                  @change="reloadChart"
                />
              </div>
            </v-card>
            <DeleteChart
              :index="index"
              :chart-id="chartId"
              @confirmDelete="deleteChart"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <IndicatorModal
      ref="indicatorModal"
      @save="updateItem"
    />
  </v-card>
</template>

<script>

import alert from '@/common/utils/alert'
import TimeUtils from '@/common/utils/time'

import DataProvider from '@/modules/chart/components/DataProvider'
import Chart from '@/modules/chart/components/chartjs/Chart.vue'
import IndicatorItem from './ChartIndicatorItem.vue'
import IndicatorModal from './ChartIndicatorModal.vue'
import DeleteChart from './DeleteChartModal.vue'

export default {

  components: {
    DataProvider,
    Chart,
    IndicatorItem,
    IndicatorModal,
    DeleteChart,
  },

  props: {
    index: {
      type: Number,
      default: null,
    },
    chartId: {
      type: String,
      default: '',
    },
    initialTitle: {
      type: String,
      default: '',
    },
    initialIndicators: {
      type: Array,
      default: () => [],
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      interval: 2,
      saving: false,

      showLegend: true,
      showDataLabel: true,

      showChart: true,
      showChartItems: true,

      title: this.initialTitle,
      indicators: this.$_.clone(this.initialIndicators),

    }
  },

  computed: {
    labels() {
      const labels = {}
      const indicators = this.$store.getters['setting/indicators']
      if (indicators === null) {
        return labels
      }

      indicators.forEach(item => {
        labels[item.id] = item.label
      })

      return labels
    },
    times() {
      const delay = 1 // this.$store.getters['user/chartDelay']

      return delay ? TimeUtils.getQuarters(8, delay) : []
    },
  },

  created() {
    // No idea why chart doesn't show up sometime
    if (!this.chartId) {
      this.reloadChartItems()
    }
  },

  methods: {
    reloadChart() {
      this.reloadChartItems()
      this.$refs.dataProvider.fetchData()
    },

    reloadChartItems() {
      this.showChartItems = false
      this.$nextTick(() => {
        this.showChartItems = true
      })
    },

    updateItem(index, data) {
      let reloadChart = false
      if (index === null) {
        reloadChart = true
        this.indicators.push(data)
      } else {
        if (this.indicators[index].id !== data.id) {
          reloadChart = true
        }
        this.$set(this.indicators, index, data)
      }

      if (reloadChart) {
        this.reloadChart()
      } else {
        this.reloadChartItems()
      }
    },

    removeItem(id) {
      for (let i = 0; i < this.indicators.length; i += 1) {
        const item = this.indicators[i]

        if (item.id === id) {
          this.indicators.splice(i, 1)
        }
      }
      this.reloadChart()
    },

    saveChart() {
      this.saving = true
      const { index } = this
      const payload = {
        title: this.title,
        indicators: this.indicators,
      }

      let request
      if (this.chartId) {
        request = this.$httpUser.put(`/api/charts/${this.chartId}`, payload)
      } else {
        request = this.$httpUser.post('/api/charts', payload)
      }

      request
        .then(resp => {
          const { id } = resp.data.data
          const data = {
            id,
            is_active: this.isActive,
            is_default: false,
            ...payload,
          }
          this.$store.commit('chart/SET_CHART', { index, data })
          alert.success('Lưu thành công.')
          this.$emit('doneBuilding')
        })
        .catch(() => {
          alert.fail('Tạo thất bại.')
        })
    },

    deleteChart() {
      this.$httpUser.delete(`/api/charts/${this.chartId}`)
        .then(() => {
          this.$store.commit('chart/REMOVE_CHART', this.index)
          alert.success('Xóa thành công.')
          this.$emit('doneBuilding')
        })
        .catch(() => {
          alert.fail('Xóa thất bại.')
        })
    },
  },

}
</script>
